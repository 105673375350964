import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { StrategyPermissionFullPipe } from '@permissions/strategy-permission-full/strategy-permission-full.pipe';
import { SEARCH_CONTROL_PROVIDER } from '@tokens/search-control.token';
import { STATUS_LIST } from '@tokens/status-list.token';
import { USER } from '@tokens/user.token';
import { ReplaySubject } from 'rxjs';
import { BUTTON_CONFIG } from '../clustering/resolvers/button-config.resolver';
import { StatusesService } from '../settings/pages/matrix-settings/services/statuses/statuses.service';
import { StrategyApiService } from './services/strategy-api/strategy-api.service';
import { StrategyFieldsService } from './services/strategy-fields/strategy-fields.service';
import { StrategyFormService } from './services/strategy-form/strategy-form.service';
import { StrategyLimitApiService } from './services/strategy-limit-api/strategy-limit-api.service';
import { StrategyLimitFormService } from './services/strategy-limit-form/strategy-limit-form.service';
import { StrategyLimitStateService } from './services/strategy-limit-state/strategy-limit-state.service';
import { StrategyStateService } from './services/strategy-state/strategy-state.service';
import { StrategyTreeService } from './services/strategy-tree/strategy-tree.service';
import { TableProcessService } from './services/table-process/table-process.service';

export const PROVIDERS = [
  SEARCH_CONTROL_PROVIDER,
  StrategyApiService,
  StrategyFormService,
  StrategyFieldsService,
  StrategyStateService,
  StrategyLimitApiService,
  StrategyLimitFormService,
  StrategyTreeService,
  StatusesService,
  StrategyLimitStateService,
  TableProcessService,
  {
    provide: STATUS_LIST,
    useFactory: () => StatusesService.cache,
    deps: [StatusesService],
  },
  {
    provide: BUTTON_CONFIG,
    useValue: new ReplaySubject(1),
  },
  StrategyPermissionFullPipe,
];

export const canMatchCreateStrategyRoute = () => {
  const user = inject(USER);
  const router = inject(Router);
  return user().strategyFull ? true : router.navigate(['strategy', 'list']);
};
